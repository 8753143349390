import { wrapFunctional } from './utils'

export const EleComponentsInstall = import('../../components/eleComponentsInstall.js' /* webpackChunkName: "components/ele-components-install" */).then(c => wrapFunctional(c.default || c))
export const SelfComponentsInstall = import('../../components/selfComponentsInstall.js' /* webpackChunkName: "components/self-components-install" */).then(c => wrapFunctional(c.default || c))
export const VantComponentsInstall = import('../../components/vantComponentsInstall.js' /* webpackChunkName: "components/vant-components-install" */).then(c => wrapFunctional(c.default || c))
export const CtaForm = import('../../components/cta/cta-form.vue' /* webpackChunkName: "components/cta-form" */).then(c => wrapFunctional(c.default || c))
export const CtaLinkTempOne = import('../../components/cta/cta-link-temp-one.vue' /* webpackChunkName: "components/cta-link-temp-one" */).then(c => wrapFunctional(c.default || c))
export const CtaLinkTempThe = import('../../components/cta/cta-link-temp-the.vue' /* webpackChunkName: "components/cta-link-temp-the" */).then(c => wrapFunctional(c.default || c))
export const CtaLinkTempTwo = import('../../components/cta/cta-link-temp-two.vue' /* webpackChunkName: "components/cta-link-temp-two" */).then(c => wrapFunctional(c.default || c))
export const NoData = import('../../components/no-data/index.vue' /* webpackChunkName: "components/no-data" */).then(c => wrapFunctional(c.default || c))
export const SvgIcon = import('../../components/svg-icon/index.vue' /* webpackChunkName: "components/svg-icon" */).then(c => wrapFunctional(c.default || c))
export const CmscontentComToHtml = import('../../components/cmscontent/comToHtml/index.vue' /* webpackChunkName: "components/cmscontent-com-to-html" */).then(c => wrapFunctional(c.default || c))
export const CmscontentContentCard = import('../../components/cmscontent/content-card/index.vue' /* webpackChunkName: "components/cmscontent-content-card" */).then(c => wrapFunctional(c.default || c))
export const CmscontentCta = import('../../components/cmscontent/cta/index.vue' /* webpackChunkName: "components/cmscontent-cta" */).then(c => wrapFunctional(c.default || c))
export const CmscontentDownloadDialog = import('../../components/cmscontent/download-dialog/index.vue' /* webpackChunkName: "components/cmscontent-download-dialog" */).then(c => wrapFunctional(c.default || c))
export const CmscontentDownloadDialogIndexM = import('../../components/cmscontent/download-dialog/index_m.vue' /* webpackChunkName: "components/cmscontent-download-dialog-index-m" */).then(c => wrapFunctional(c.default || c))
export const CmscontentMNav = import('../../components/cmscontent/m-nav/index.vue' /* webpackChunkName: "components/cmscontent-m-nav" */).then(c => wrapFunctional(c.default || c))
export const CmscontentPFooter = import('../../components/cmscontent/p-footer/footer.vue' /* webpackChunkName: "components/cmscontent-p-footer" */).then(c => wrapFunctional(c.default || c))
export const CmscontentPNav = import('../../components/cmscontent/p-nav/index.vue' /* webpackChunkName: "components/cmscontent-p-nav" */).then(c => wrapFunctional(c.default || c))
export const CmscontentSkeletonScreen = import('../../components/cmscontent/skeletonScreen/index.vue' /* webpackChunkName: "components/cmscontent-skeleton-screen" */).then(c => wrapFunctional(c.default || c))
export const CmscontentSkeletonScreenM = import('../../components/cmscontent/skeletonScreenM/index.vue' /* webpackChunkName: "components/cmscontent-skeleton-screen-m" */).then(c => wrapFunctional(c.default || c))
export const CmscontentMNavCompomentsSubMenu = import('../../components/cmscontent/m-nav/compoments/subMenu/index.vue' /* webpackChunkName: "components/cmscontent-m-nav-compoments-sub-menu" */).then(c => wrapFunctional(c.default || c))
export const CmscontentPNavComponentsSubItem = import('../../components/cmscontent/p-nav/components/subItem/index.vue' /* webpackChunkName: "components/cmscontent-p-nav-components-sub-item" */).then(c => wrapFunctional(c.default || c))
export const CmscontentPNavComponentsSubMenu = import('../../components/cmscontent/p-nav/components/subMenu/index.vue' /* webpackChunkName: "components/cmscontent-p-nav-components-sub-menu" */).then(c => wrapFunctional(c.default || c))
