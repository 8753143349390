// 全局方法
import Vue from 'vue'
import engconfig from '../envconfig'
let utils = {
    install(Vue) {
        (Vue.prototype.insertScriptCode = function(codeStr) {
            let scriptString = codeStr
            const createScript = document.createElement('script')
            createScript.type = 'text/javascript'
            scriptString = scriptString.substring(8, scriptString.length - 10)
            createScript.innerHTML = `${scriptString}`
            if (process.client) {
                document.body.append(createScript)
            }
        }),
        //截取字符串中的网址
        (Vue.prototype.httpString = function(val) {
            var reg = /(https?|http|ftp|file):\/\/[-A-Za-z0-9+&@#/%?=~_|!:,.;]+[-A-Za-z0-9+&@#/%=~_|]/g;
            val = val.match(reg);
            return val
        }),
        // 图片 环境
        (Vue.prototype.prefixImageUrl = function() {
            // if (env === 'development' || env === 'local') {
            return engconfig[process.env.NODE_ENV].VUE_APP_DOMAIN
        })
    },
}
Vue.use(utils)
