//
//
//
//
//
//
//
//
//
//

export default {
  name: 'SvgIcon',
  props: {
    iconClass: {
      type: String,
      required: true,
    },
    styleObj: {
      type: Object,
      default() {
        return {}
      },
    },
    width: {
      type: [String, Number],
      default: '10',
    },
    height: {
      type: [String, Number],
      default: '10',
    },
    color: {
      type: String,
      default: '',
    },
    addStyles: {
      type: Object,
      default() {
        return {}
      },
    },
    className: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      baseStyles: {
        width: this.width + 'px',
        height: this.height + 'px',
        color: this.color,
      },
    }
  },
  computed: {
    iconName() {
      return `#${this.iconClass}`
    },
    svgClass() {
      if (this.className) {
        return 'svg-icon ' + this.className
      } else {
        return 'svg-icon'
      }
    },
  },
  methods: {
    onClick() {
      this.$emit('Click')
    },
  },
}
