import {
  Button,
  Swipe,
  SwipeItem,
  Collapse,
  CollapseItem,
  Icon,
  Dialog,
  Toast,
} from 'vant'

export default {
  install(Vue) {
    Vue.use(Button)
    Vue.use(Swipe)
    Vue.use(SwipeItem)
    Vue.use(Collapse)
    Vue.use(CollapseItem)
    Vue.use(Icon)
    Vue.use(Dialog)
    Vue.use(Toast)
  },
}
