export default ({ app: { $request } }, inject) => {
  inject('user', {

    /**
   *  生成网页授权链接
   * @param {string} org_id - 组织id
   * @param {string} wechat - 公众号唯一表示
   * @param {string} callbackUrl -  base64encode页面链接
   */
    getWebpageoauthurl(params) {
      return $request.post('/ma/User/Bindwechatv2/webpageoauthurl', params)
    },
    /**
  *  获取oepnid
  * @param {string} org_id - 组织id
  * @param {string} wechat - 公众号唯一表示
  * @param {string} wechatCode - 1发布 2未发布 页面链接带的参数
  */
    getOpenId(params) {
      return $request.post('/ma/User/Bindwechatv2/fansInfoByCode', params)
    },
  })
}

//上报信息
//wechat_openid:''
//wechat_appid:''
//wechat_fans_info:{}
