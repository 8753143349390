export default ({ app }) => {
  app.router.afterEach((to) => {
    console.log('to', to)
    // 滚动容器的scrollTop = 0
    let whiteList = ['cmscontent-id']
    if (whiteList.includes(to.name)) {
      // document.getElementById('__layout').scrollTop = 0
      console.log(whiteList.includes(to.name))
    }
  })
}
