import Vue from 'vue'
import engconfig from '../envconfig'
import eleComponentsInstall from '~/components/eleComponentsInstall' // 注册elementui组件
import vantComponentsInstall from '~/components/vantComponentsInstall' // 注册elementui组件
import '~/components/selfComponentsInstall' // 注册自己的组件组件
import '~/assets/scss/element-variables.scss' // elementUI 自定义主题色
import VueLazyload from 'vue-lazyload'

Vue.use(VueLazyload)

Vue.use(eleComponentsInstall)

Vue.use(vantComponentsInstall)

Vue.prototype.$trackUrl = engconfig[process.env.NODE_ENV].TRACK_URL
Vue.prototype.$domain = engconfig[process.env.NODE_ENV].VUE_APP_DOMAIN
Vue.prototype.$local = engconfig[process.env.NODE_ENV] === 'local' ?'':'/web-mobile'

