import Vue from 'vue'

import {
  Input,
} from 'element-ui' // 注册elementui组件
Vue.use( {
  install(Vue) {
    Vue.use(Input)
  },
})


