import Vue from 'vue'
import {
  Field,
  Popup,
  DatetimePicker,
  Picker,
  Skeleton,
  Search,
} from 'vant'




Vue.use( {
  install(Vue) {
    Vue.use(Field)
    Vue.use(Popup)
    Vue.use(DatetimePicker)
    Vue.use(Picker)
    Vue.use(Skeleton)
    Vue.use(Search)
  },
})
