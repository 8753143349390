export default ({ app: { $request } }, inject) => {
  inject('cta', {
    /**
     * 获取cta详情
     * @param {number} org_id - 组织id
     * @param {number} id - 页尺寸
     * @param {number} release - 1发布 2未发布 页面链接带的参数
     */
    getCtaDetail(params) {
      return $request.post('/cms/cend-cta/getdetail', params)
    },
  })
}
