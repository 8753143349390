import {
  Input,
  Button,
  Select,
  Option,
  Notification,
  Message,
  Carousel,
  CarouselItem,
  Menu,
  Submenu,
  MenuItem,
  Image,
} from 'element-ui'

export default {
  install(Vue) {
    Vue.use(Input)
    Vue.use(Select)
    Vue.use(Option)
    Vue.use(Button)
    Vue.use(Carousel)
    Vue.use(CarouselItem)
    Vue.use(Menu)
    Vue.use(Submenu)
    Vue.use(MenuItem)
    Vue.use(Image)
    Vue.prototype.$message = Message
    Vue.prototype.$notify = Notification
  },
}
