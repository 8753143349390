import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _5a974187 = () => interopDefault(import('../pages/contents/index.vue' /* webpackChunkName: "" */))
const _6c166bd1 = () => interopDefault(import('../pages/m/contents/index' /* webpackChunkName: "" */))
const _3f796300 = () => interopDefault(import('../pages/contents/preview/index.vue' /* webpackChunkName: "" */))
const _2445f9af = () => interopDefault(import('../pages/contents/_id.vue' /* webpackChunkName: "" */))
const _57531919 = () => interopDefault(import('../pages/forms/preview/_id.vue' /* webpackChunkName: "" */))
const _b88fa340 = () => interopDefault(import('../pages/forms/_id.vue' /* webpackChunkName: "" */))
const _00042568 = () => interopDefault(import('../pages/contents/preview/_id.vue' /* webpackChunkName: "" */))
const _7b496d62 = () => interopDefault(import('../pages/m/contents/preview/index.vue' /* webpackChunkName: "" */))
const _b2e992de = () => interopDefault(import('../pages/m/contents/_id.vue' /* webpackChunkName: "" */))
const _5bb7604a = () => interopDefault(import('../pages/m/contents/preview/_id.vue' /* webpackChunkName: "" */))
const _fc0a5cdc = () => interopDefault(import('../pages/cta/preview/index.vue' /* webpackChunkName: "" */))
const _1ee2c774 = () => interopDefault(import('../pages/cta/link/index.vue' /* webpackChunkName: "" */))
const _f17b5c20 = () => interopDefault(import('../pages/m/cta/preview/index.vue' /* webpackChunkName: "" */))
const _130faeb0 = () => interopDefault(import('../pages/m/cta/link/index.vue' /* webpackChunkName: "" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/",
    component: _5a974187,
    name: "Home"
  }, {
    path: "/contents",
    component: _5a974187,
    name: "Home1"
  }, {
    path: "/m",
    component: _6c166bd1
  }, {
    path: "/contents/preview",
    component: _3f796300,
    name: "HomePreview"
  }, {
    path: "/:id",
    component: _2445f9af
  }, {
    path: "/contents/:id",
    component: _2445f9af
  }, {
    path: "/forms/preview/:id",
    component: _57531919
  }, {
    path: "/forms/:id",
    component: _b88fa340
  }, {
    path: "/contents/preview/:id",
    component: _00042568
  }, {
    path: "/m/contents/preview",
    component: _7b496d62
  }, {
    path: "/m/contents/:id",
    component: _b2e992de
  }, {
    path: "/m/contents",
    component: _6c166bd1
  }, {
    path: "/m/:id",
    component: _b2e992de
  }, {
    path: "/m/contents/preview/:id",
    component: _5bb7604a
  }, {
    path: "/cta/preview",
    component: _fc0a5cdc
  }, {
    path: "/cta/link",
    component: _1ee2c774
  }, {
    path: "/m/contents/cta/preview",
    component: _f17b5c20
  }, {
    path: "/m/contents/cta/link",
    component: _130faeb0
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
