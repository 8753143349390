const config = {
  local: {
    DESCN: 'local环境',
    API_BASE_URL: 'https://app-stg2.beschannels.com/api',
    API_SCRM_BASE_URL: 'https://stg2.ma.scrmtech.com',
    VUE_APP_DOMAIN: 'https://cdn-storage-test.ma.scrmtech.com/',
    TRACK_URL: 'https://exp-stg.beschannels.com/collect/collect.gif',
  },
  mock: {
    DESCN: 'mock环境',
    API_BASE_URL:
      'https://mockapi.eolinker.com/mqttYxy8db7b0bccdf030815beb825070492c1c554a3c69',
  },
  development: {
    DESCN: '开发环境',
    API_BASE_URL: 'https://app-dev.beschannels.com/api',
    VUE_APP_DOMAIN: 'https://cdn-storage-test.ma.scrmtech.com/',
    TRACK_URL: 'https://bomb-dev.ma.scrmtech.com/collect-test/collect.gif',
  },
  test: {
    DESCN: 'test环境',
    API_BASE_URL: 'https://app-test.beschannels.com/api',
    VUE_APP_DOMAIN: 'https://cdn-storage-test.ma.scrmtech.com/',
    TRACK_URL: 'https://bomb-dev.ma.scrmtech.com/collect-test/collect.gif',
  },
  fix_20220117: {
    DESCN: 'qrj环境',
    API_BASE_URL: 'https://app-test.beschannels.com/api',
    VUE_APP_DOMAIN: 'https://cdn-storage-test.ma.scrmtech.com/',
    TRACK_URL: 'https://bomb-dev.ma.scrmtech.com/collect-test/collect.gif',
  },
  stg: {
    DESCN: 'stg集成环境',
    API_BASE_URL: 'https://app-stg.beschannels.com/api',
    API_SCRM_BASE_URL: 'https://stg.ma.scrmtech.com',
    VUE_APP_DOMAIN: 'https://cdn-storage-test.ma.scrmtech.com/',
    TRACK_URL: 'https://exp-stg.beschannels.com/collect/collect.gif',//m埋点地址
  },
  release: {
    DESCN: '预发布环境',
    API_BASE_URL: 'https://app-stress.beschannels.com/api',
    VUE_APP_DOMAIN: 'https://cdn-storage-test.ma.scrmtech.com/',
    TRACK_URL: 'https://bomb-dev.ma.scrmtech.com/collect-test/collect.gif',
  },
  production: {
    DESCN: '生产环境',
    API_BASE_URL: 'https://app.beschannels.com/api',
    VUE_APP_DOMAIN: 'https://img02.ma.scrmtech.com/',
    TRACK_URL: 'https://exp.beschannels.com/collect/collect.gif',
  },
}

export default config
