export default ({ app: { $request } }, inject) => {
  inject('api', {
    /**
     * 获取首页一屏 List
     * @param {number} org_id - 组织id
     * @param {number} page_num - 页尺寸
     * @param {number} is_release - 1发布 2未发布 页面链接带的参数
     */
    getHomeList(params) {
      return $request.post('/cms/cend-pageindex/list', params)
    },
    /**
     * 获取首页信息
     * @param {number} org_id - 组织id
     */
    getHomeInfo(params) {
      return $request.post('/cms/cend-pageindex/details', params)
    },
    /**
     * 获取首页导航 navlist
     * @param {number} org_id - 组织id
     */
    getHomeNavList(params) {
      return $request.post('/cms/cend-type/list', params)
    },
    /**
     * 首页Contentlist 筛选接口
     * @param {number} org_id - 组织id
     * @param {number} classification - 分类id
     * @param {number} name - 名称
     * @param {number} page - 页码
     * @param {number} page_size - 页尺寸
     */
    getContentList(params) {
      return $request.post('/cms/cend-content/list', params)
    },
    /**
     *  获取content详情
     * @param {number} org_id - 组织id
     * @param {number} id - 内容id
     * @param {number} is_release - 1发布 2未发布 页面链接带的参数
     */
    getContentDetail(params) {
      return $request.post('/cms/cend-content/details', params)
    },
     /**
     *  获取企微活码
     */
    getMochatCodeByUser(params){
      return $request.post('/mochat/channel-code/code/handleCodeByUser', params)
    },
    // 获取当前客户的公众号的jssdk
    getWechatJsSdk(params){
      return $request.post('/ma/User/Bindwechatv2/getWechatJsSdk', params)
    }
   
  })
}