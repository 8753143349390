if(typeof Vue !== 'undefined'){
  module.exports = Vue;
}else{
  if (process.env.NODE_ENV === 'production') {
    module.exports = require('./vue.common.prod.js')
  } else {
    module.exports = require('./vue.common.dev.js')
  }

}
