export default function ({ app, req, redirect, route }) {
  if (req && req.headers) {
    if (req.headers['org-id']) {
      let org_id = req.headers['org-id']
      app.$cookies.set('org_id', org_id)
      app.store.commit('SET_ORG_ID', org_id)
    } else {
      // app.store.commit('SET_ORG_ID', 'org17a8b62068bb8d908b41e4162a17eeaf')//stg
      // app.store.commit('SET_ORG_ID','org88cbf3abfe238d30f51bf4d53647eb85')//test
      /*
        dev13@scrmtech.com--->org14278e27a81341a1ae61464feca8587b
        stg2test@scrmtech.com--->orgb875b0798e9f4cda7f770f3a08fb5925
      */ 
      app.store.commit('SET_ORG_ID', 'orgb875b0798e9f4cda7f770f3a08fb5925')
    }
  }
  let isMobile = (ua) => {
    return !!ua.match(/AppleWebKit.*Mobile.*/)
  }
  // 若终端为m但路径中不包含/m/或者/m的，重定向到m
  if (
    (route.fullPath.indexOf('demo') > 0)
  ) {
    //redirect('/m' + route.fullPath)
    return
  }


  // 路由的参数判断，如果有pm参数，那么走pm手动重定向 start
  if (
    route.query.pm === 'm' &&
    (route.fullPath.indexOf('/m') < 0 || route.fullPath.indexOf('/m/') < 0) && (route.fullPath.indexOf('/forms') < 0)
  ) {
    if (route.fullPath != '/m') {
      console.log('路由地址有pm=====>', route.fullPath)
      redirect('/m' + route.fullPath)
    }
  } else if (
    route.query.pm === 'p' &&
    (route.fullPath.indexOf('/m') > -1 || route.fullPath.indexOf('/m/') > -1)
  ) {
    let url = route.fullPath.substring(2)

    redirect(url)
  }
  // 路由的参数判断，如果有pm参数，那么走pm手动重定向 end
  // 常规机型判断 start
  let userAgent = req ? req.headers['user-agent'] : navigator.userAgent || ''
  // 若终端为pc但路径中包含/m或/m/的，重定向到pc
  if (
    !route.query.pm &&
    (route.fullPath.indexOf('/m') > -1 || route.fullPath.indexOf('/m/') > -1) &&
    !isMobile(userAgent)
    && (!route.fullPath.includes('http') && !route.fullPath.includes('https'))
  ) {
    let url = route.fullPath.substring(2)
    if (!url.includes('http')) {
      redirect(url)
    }
  }

  // 若终端为m但路径中不包含/m/或者/m的，重定向到m
  if (
    !route.query.pm &&
    (route.fullPath.indexOf('/m') < 0 || route.fullPath.indexOf('/m/') < 0) &&
    isMobile(userAgent) && (route.fullPath.indexOf('/forms') < 0)
  ) {
    if (route.fullPath != '/m') {
      //console.log('路由地址无m=====>', route.fullPath, route)
      redirect('/m' + route.fullPath)
    }
  }
  // 常规机型判断 end
}
