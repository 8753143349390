import engconfig from '../envconfig'
export default function ({ app: { $axios } }) {
  if (process.env.NODE_ENV !== 'local') {
    $axios.defaults.baseURL = engconfig[process.env.NODE_ENV].API_BASE_URL
  }
  $axios.defaults.timeout = 30000
  $axios.interceptors.request.use((config) => {
    return config
  })
  $axios.interceptors.response.use((response) => {
    if (/^[4|5]/.test(response.status)) {
      return Promise.reject(response.statusText)
    }
    return response.data
  })
}
